<template>
  <section id="contact" class="contact">
    <h1 class="heading">Get in Touch <span class="kuning">with Us</span></h1>
  </section>
  <div class="contact-in">
    <div class="contact-map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d247.34674979440086!2d112.79832710366303!3d-7.292154914020029!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fa6fd83c1127%3A0x8bfe4b94ec1f0aa!2sJl.%20Keputih%20Gg.%20III%20B%20Blok%20B%20No.1%2C%20Keputih%2C%20Kec.%20Sukolilo%2C%20Surabaya%2C%20Jawa%20Timur%2060111!5e0!3m2!1sen!2sid!4v1714664984432!5m2!1sen!2sid"
        width="100%"
        height="auto"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <div class="contact-form">
      <form @submit.prevent="submitForm">
        <input type="text" name="entry.2006070760" placeholder="Nama Lengkap" class="contact-form-txt" required v-model="nama" />
        <input type="text" name="entry.1045672640" required placeholder="Nomor Handphone" class="contact-form-email" v-model="phone" />
        <input type="email" name="entry.188427635" placeholder="Email" class="contact-form-email" required v-model="email" />
        <input type="text" name="entry.1513459381" placeholder="Subjek (ex : Konsultasi, etc)" class="contact-form-email" required v-model="subjek" />
        <textarea type="text" name="entry.851465469" placeholder="Masukkan Pesanmu..." class="contact-form-txtarea" required v-model="message"></textarea>

        <!-- Bagian Checkbox untuk Pilihan Alat -->
        <div style="margin-left: 2rem; margin-bottom: 20px">
          <label><input type="checkbox" name="entry.307548085" value="Desain Grafis" v-model="alat" /> Desain Grafis</label><br />
          <label><input type="checkbox" name="entry.307548085" value="Photobooth" v-model="alat" /> Photobooth</label><br />
          <label><input type="checkbox" name="entry.307548085" value="Web Development" v-model="alat" /> Web Development</label>
        </div>

        <input type="hidden" name="submit" value="Submit" />
        <input style="font-weight: bold" type="submit" value="Submit" class="contact-form-btn" required />
      </form>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "FormPage",
  data() {
    return {
      nama: "",
      phone: "",
      email: "",
      subjek: "",
      message: "",
      alat: [], // ubah menjadi array
    };
  },
  methods: {
    async submitForm() {
      const formData = new FormData();
      formData.append("entry.2006070760", this.nama);
      formData.append("entry.1045672640", this.phone);
      formData.append("entry.188427635", this.email);
      formData.append("entry.1513459381", this.subjek);
      formData.append("entry.851465469", this.message);

      // Gabungkan array alat menjadi satu string, misalnya dipisahkan koma
      const selectedAlat = this.alat.join(", ");
      formData.append("entry.307548085", selectedAlat);

      await fetch("https://docs.google.com/forms/d/e/1FAIpQLScE8CXDNo0KVbhsK6IRl54UKIIkuWNx-h-y9WBJRO-eArlzEw/formResponse", {
        method: "POST",
        body: formData,
        mode: "no-cors",
      });

      Swal.fire({
        icon: "success",
        title: "Responmu Terkirim",
        text: "Tunggu Tim Kami Menghubungimu Kembali Paling Lambat 2x24 Jam",
        customClass: {
          title: "swal2-title",
          text: "swal2-textarea",
          confirmButtonText: "swal2-confirm",
          footer: "swal2-footer",
          popup: "swal-popup",
        },
      }).then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&family=Poppins&display=swap");
.contact {
  text-align: center;
  align-items: center;
}

.kuning {
  color: var(--kuning);
  font-size: 70px;
  font-family: "Montserrat";
  font-weight: 800;
}

.contact .heading {
  margin-bottom: 5rem;
  padding-top: 80px;
  color: var(--biru);
  font-size: 70px;
  font-family: "Montserrat";
  font-weight: 800;
}

.contact-in {
  width: 80%;
  height: 600px;
  margin: auto auto 5rem auto;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px #666;
}

.contact-map {
  width: 100%;
  height: 100%;
  flex: 50%;
}
.contact-map iframe {
  width: 100%;
  height: 100%;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-bottom: 2rem;
}
.contact-form {
  width: 100%;
  height: auto;
  flex: 50%;
  text-align: left;
  padding-top: 2rem;
}
.contact-form-txt {
  margin-left: 2rem;
  width: 85%;
  height: 40px;
  color: #000;
  border: 1px solid #bcbcbc;
  border-radius: 10px;
  outline: none;
  margin-bottom: 20px;
  padding: 15px;
}
.contact-form-email {
  margin-left: 2rem;
  width: 85%;
  height: 40px;
  color: #000;
  border: 1px solid #bcbcbc;
  border-radius: 10px;
  outline: none;
  margin-bottom: 20px;
  padding: 15px;
}
/* .contact-form-txt::placeholder
		{
			color: #aaa;
            font-size: 16px;
			font-family: "Poppins", sans-serif;
			font-weight: 500;
		} */
/* .contact-form-email::placeholder
		{
			color: #aaa;
            font-size: 16px;
			font-family: "Poppins", sans-serif;
			font-weight: 500;
		} */
.contact-form-txtarea {
  margin-left: 2rem;
  width: 85%;
  height: 130px;
  color: #000;
  border: 1px solid #bcbcbc;
  border-radius: 10px;
  outline: none;
  margin-bottom: 20px;
  padding: 15px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
/* .contact-form-txtarea::placeholder
		{
			color: #aaa;
            font-size: 1.5rem;
		} */

.contact-form-btn {
  margin-left: 2rem;
  outline: none;
  border: none;
  border-radius: 10px;
  background: var(--biru);
  border-style: none;
  border-color: none;
  cursor: pointer;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-inline: 1rem;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-size: 18px;
}
.contact-form-btn:hover {
  transform: scale(0.9);
}
.contact-form-phone {
  margin-left: 2rem;
  width: 95%;
  height: 40px;
  color: #000;
  border: 1px solid #bcbcbc;
  border-radius: 10px;
  outline: none;
  margin-bottom: 20px;
  padding: 15px;
}
/* .contact-form-phone::placeholder
		{
			color: #aaa;
            font-size: 1.5rem;
		} */

.contact {
  text-align: center;
  align-items: center;
}

.contact .heading {
  margin-bottom: 3rem;
  margin-top: -2rem;
}

@media (max-width: 900px) {
  .contact .heading {
    font-size: 3rem;
  }

  .kuning {
    font-size: 3rem;
  }

  .paragraf p {
    font-size: 16px;
    margin-left: 50px;
    margin-right: 50px;
  }

  .contact-map {
    height: 30%;
    flex: 50%;
    padding-right: 2rem;
  }

  .contact-in {
    height: 800px;
  }

  .contact-form {
    margin-top: -60px;
  }

  .contact-form-btn {
    font-size: 20px;
    height: 2.5rem;
    width: 11rem;
    margin-left: 13.5rem;
  }

  /* .contact-form-email::placeholder
		{
            font-size: 12px;
		} */
}

@media (max-width: 900px) {
  .contact .heading {
    font-size: 2rem;
  }

  .kuning {
    font-size: 2rem;
  }

  .paragraf p {
    font-size: 16px;
    margin-left: 50px;
    margin-right: 50px;
  }

  .contact-form-btn {
    margin-left: 14.5rem;
  }

  .contact-form-txt {
    width: 90%;
  }
  .contact-form-email {
    width: 90%;
  }
  .contact-form-txtarea {
    width: 90%;
  }
}
@media (max-width: 768px) {
  .contact .heading {
    font-size: 46px;
  }

  .kuning {
    font-size: 46px;
  }

  .paragraf p {
    font-size: 16px;
    margin-left: 50px;
    margin-right: 50px;
  }

  .contact-form-btn {
    margin-left: 13.5rem;
  }

  .contact-form-txt {
    width: 90%;
  }
  .contact-form-email {
    width: 90%;
  }
  .contact-form-txtarea {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .contact .heading {
    font-size: 30px;
  }

  .kuning {
    font-size: 30px;
  }

  .paragraf p {
    font-size: 14px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .contact-form-btn {
    margin-left: 4rem;
  }

  .contact-form-txt {
    width: 80%;
  }
  .contact-form-email {
    width: 80%;
  }
  .contact-form-txtarea {
    width: 80%;
  }
}
</style>
